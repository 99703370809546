
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import store from "@/store";
import NotificationCard from "@/components/Notification-Card.vue";
export default defineComponent({
  name: "BarcodeForm",
  components: {
    InputText,
    Card,
    Button,
    NotificationCard,
  },
  props: {
    menu: String,
  },
  mounted(){
    this.autoFocusInput()
  },
  updated(){
    this.autoFocusInput()
  },
  data() {
    return {
      barcode: "",
      loading: false,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      barcode: { required },
    };
  },
  computed: {
    item() {
      switch (this.menu) {
        case "INVENTORY":
        case "TRANSFER":
          return "Part Number";
        case "RECEIVING":
          return "Work Order Number";
        case "PICKING":
          return "Work Order Number";
      }
      return "";
    },
  },
  methods: {
    /*
    * TODO: get list of valid locations
      TODO: get fractions ok for part
      TODO: get lot control for part
      TODO: get bin control for part

    *
    */
    async fetchTransfer() {
      try {
        const client = store.getters["session/getClient"];
        const part = store.dispatch("inventory/fetchPart", {
          client: store.getters["session/getClient"],
          id: this.barcode,
          correls: "um"
        });
        if (await part) {
          this.$router.push("/scan/transfer/details");
        }
      } catch (error: any) {
        this.loading = false;
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        store.dispatch("notification/add", notification, { root: true });
      }
    },
    autoFocusInput(){
      (this.$refs.scan as any)?.$el.focus()
    },
    /*
      @desc This function fetches the picker and work order
      @desc if succesful, it pushes router to picking/details
    */
    fetchPicker() {
      store
        .dispatch("workorder/fetchPick", {
          id: this.barcode,
          client: store.getters["session/getClient"],
        })
        .then((response) => {
          store
            .dispatch("workorder/fetchOrder", {
              client: store.getters["session/getClient"],
              id: this.barcode,
            })
            .then((response) => {
              if (store.getters["workorder/getStatus"] != "C") {
                this.$router.push("/scan/picking/details");
              } else {
                const notification = {
                  type: "error",
                  message: "Work Order is closed",
                };
                store.dispatch("notification/add", notification, {
                  root: true,
                });
              }
            });
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    fetchInventory() {
      store.dispatch("inventory/fetchInventory", 
      {
        client: store.getters["session/getClient"],
        id: this.barcode,
        correls: "um"
      })
      .then((response) => {
        this.loading = false;
        this.$router.push("/scan/inventory/details");
      })
      .catch((error) => { this.loading = false });
    },
    async fetchdata() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.loading = true;
        switch (this.menu) {
          case "INVENTORY":
            this.fetchInventory();
            break;
          case "PICKING":
            this.fetchPicker();
            break;
          case "TRANSFER":
            this.fetchTransfer();
            break;
          default:
            break;
        }
      } else {
        const notification = {
          type: "error",
          message: "Please complete form",
        };
        store.dispatch("notification/add", notification, { root: true });
      }
    },
  },
});
